import * as React from 'react';

import Layout from '@topginger/ncs-layouts-home/src';
import HomeGrid from '@topginger/ncs-components-home-grid';

// import * as styles from './home.module.css';

interface IHomePageProps extends NCS.IPageProps {
  homeLinks: NCS.IHomePageProps[];
  heroImage: string;
  heroTitle: string;
}

const Homepage: React.FC<IHomePageProps> = ({ alert, homeLinks, heroImage, heroTitle }) => (
  <Layout isDark={false} alert={alert} heroImage={heroImage} heroTitle={heroTitle}>
    <HomeGrid homeLinks={homeLinks} />
  </Layout>
);

export default Homepage;
