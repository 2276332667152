// import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import Page from '@topginger/ncs-pages-404/src';

import { siteGlobalSettings } from '../hooks/global-settings';

import SEO from '../components/seo.component';

const ErrorPage: React.FC = () => {
  return (
    <>
      <SEO />
      <Page alert={siteGlobalSettings()} />
    </>
  );
};

export default ErrorPage;
