import * as React from 'react';

import { isBrowser } from '@topginger/ncs-utils-browser-support/src';
import Wrapper from '@topginger/ncs-components-wrapper/src';
import Header from '@topginger/ncs-components-header';
import Footer from '@topginger/ncs-components-footer';
import AppTemplate from '@topginger/ncs-components-app-template/src';
import Alert from '@topginger/ncs-components-alert';

import * as styles from './regular.module.css';

interface ILayoutProps {
  alert: NCS.IAlertProps;
  isDark?: boolean;
}

const RegularLayoutComponent: React.FC<ILayoutProps> = ({ alert, isDark = false, children }) => {
  let isBannerOpen;
  if (isBrowser) {
    isBannerOpen = !sessionStorage.getItem('isBannerOpen');
  }
  const [isOpen, setOpenStatus] = React.useState<boolean>(true);
  const { alertmessagetitle, alertmessagetext, alertmessageactive, alertmessagetype } = alert;
  const handleClose = () => {
    setOpenStatus(false);
    sessionStorage.setItem('isBannerOpen', 'false');
  };

  React.useEffect(() => {
    setOpenStatus(isBannerOpen);
  }, [isBannerOpen]);

  return (
    <AppTemplate isDark={isDark}>
      {alert && alertmessageactive && isOpen && (
        <Alert title={alertmessagetitle} text={alertmessagetext} alertType={alertmessagetype} onClick={handleClose} />
      )}
      <Header isDark={isDark} />
      <main className={styles.layout}>
        <Wrapper>{children}</Wrapper>
      </main>
      <Footer />
    </AppTemplate>
  );
};
export default RegularLayoutComponent;
