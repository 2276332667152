import { useStaticQuery, graphql } from 'gatsby';
export const siteGlobalSettings = () => {
  const { prismic } = useStaticQuery(
    graphql`
      query globalSettings {
        prismic {
          allGlobal_settingss {
            edges {
              node {
                alertmessagetype
                alertmessagetitle
                alertmessagetext
                alertmessageactive
              }
            }
          }
        }
      }
    `,
  );

  return prismic.allGlobal_settingss.edges[0].node;
};
