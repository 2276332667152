import * as React from 'react';
import cx from 'classnames';

import './core.css';

import * as styles from './template.module.css';

interface ILayoutProps {
  isDark: boolean;
}

const AppTemplate: React.FC<ILayoutProps> = ({ isDark = false, children }) => {
  return <div className={cx(styles.app, { [styles.isDark]: isDark })}>{children}</div>;
};

export default AppTemplate;
