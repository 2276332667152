import * as React from 'react';

export interface IHookSideTray {
  title: string;
  content: any;
}

const initialState = {
  title: '',
  content: null,
};

export const useSideTray = () => {
  const [sideTrayContent, setSideTrayContent] = React.useState<IHookSideTray>(initialState);
  const clearSideTrayContent = () => setSideTrayContent(initialState);

  const handleSideTray = (title, content) => {
    setSideTrayContent({ title, content });
  };

  return {
    handleSideTray,
    sideTrayContent,
    clearSideTrayContent,
  };
};
