import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import PeoplePage from '@topginger/ncs-pages-people/src';

import { siteGlobalSettings } from '../hooks/global-settings';

import SEO from '../components/seo.component';

const People: React.FC = () => {
  const data: any = useStaticQuery(graphql`
    query OurPeople {
      prismic {
        allOur_peoples {
          edges {
            node {
              ourpeople {
                people {
                  ... on PRISMIC_People {
                    name
                    photo
                    position
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const ourPeople = data.prismic.allOur_peoples.edges[0].node.ourpeople.map(
    ({
      people: {
        name,
        photo,
        position,
        _meta: { uid: id },
      },
    }) => ({
      name,
      photo,
      position,
      id,
    }),
  );

  return (
    <>
      <SEO />
      <PeoplePage alert={siteGlobalSettings()} ourPeople={ourPeople} />
    </>
  );
};

export default People;
