import * as React from 'react';

import Layout from '@topginger/ncs-layouts-regular/src';

import * as styles from './404.module.css';

const ErrorPage: React.FC<NCS.IPageProps> = ({ alert }) => (
  <Layout isDark={false} alert={alert}>
    <div className={styles.errorPageContent}>Error page</div>
  </Layout>
);

export default ErrorPage;
