import * as React from 'react';

import Text, { TextStyles } from '@topginger/ncs-components-text/src';
import Link from '@topginger/ncs-components-link/src';

// import * as styles from './person-contact-details.module.css';

interface IPersonContactDetails {
  phoneNumber: string;
  email: string;
}

const PersonContactDetails: React.FC<IPersonContactDetails> = ({ phoneNumber, email }) => {
  return (
    <address>
      <Text element="h3" type={TextStyles.h3}>
        Contact
      </Text>
      {phoneNumber && (
        <>
          <Text element="span">DDI</Text>
          <br />
          <Text element="p">
            <Link to={`tel:${phoneNumber}`} external={true}>
              {phoneNumber}
            </Link>
          </Text>
        </>
      )}

      {email && (
        <>
          <Text element="span">EMAIL</Text>
          <br />
          <Text element="p">
            <Link to={`mailto:${email}`} external={true}>
              {email}
            </Link>
          </Text>
        </>
      )}
    </address>
  );
};

export default PersonContactDetails;
