import * as tslib_1 from "tslib";
export * from 'apollo-client';
export * from 'apollo-link';
export * from 'apollo-cache-inmemory';
import { ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { withClientState } from 'apollo-link-state';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import ApolloClient from 'apollo-client';
export { gql, HttpLink };
var PRESET_CONFIG_KEYS = ['request', 'uri', 'credentials', 'headers', 'fetch', 'fetchOptions', 'clientState', 'onError', 'cacheRedirects', 'cache', 'name', 'version'];
var DefaultClient = function (_super) {
  tslib_1.__extends(DefaultClient, _super);
  function DefaultClient(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = this;
    if (config) {
      var diff = Object.keys(config).filter(function (key) {
        return PRESET_CONFIG_KEYS.indexOf(key) === -1;
      });
      if (diff.length > 0) {
        console.warn('ApolloBoost was initialized with unsupported options: ' + ("" + diff.join(' ')));
      }
    }
    var request = config.request,
      uri = config.uri,
      credentials = config.credentials,
      headers = config.headers,
      fetch = config.fetch,
      fetchOptions = config.fetchOptions,
      clientState = config.clientState,
      cacheRedirects = config.cacheRedirects,
      errorCallback = config.onError,
      name = config.name,
      version = config.version;
    var cache = config.cache;
    if (cache && cacheRedirects) {
      throw new Error('Incompatible cache configuration. If providing `cache` then ' + 'configure the provided instance with `cacheRedirects` instead.');
    }
    if (!cache) {
      cache = cacheRedirects ? new InMemoryCache({
        cacheRedirects: cacheRedirects
      }) : new InMemoryCache();
    }
    var stateLink = clientState ? withClientState(tslib_1.__assign({}, clientState, {
      cache: cache
    })) : false;
    var errorLink = errorCallback ? onError(errorCallback) : onError(function (_a) {
      var graphQLErrors = _a.graphQLErrors,
        networkError = _a.networkError;
      if (graphQLErrors) {
        graphQLErrors.map(function (_a) {
          var message = _a.message,
            locations = _a.locations,
            path = _a.path;
          return console.log("[GraphQL error]: Message: " + message + ", Location: " + (locations + ", Path: " + path));
        });
      }
      if (networkError) {
        console.log("[Network error]: " + networkError);
      }
    });
    var requestHandler = request ? new ApolloLink(function (operation, forward) {
      return new Observable(function (observer) {
        var handle;
        Promise.resolve(operation).then(function (oper) {
          return request(oper);
        }).then(function () {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        }).catch(observer.error.bind(observer));
        return function () {
          if (handle) {
            handle.unsubscribe();
          }
        };
      });
    }) : false;
    var httpLink = new HttpLink({
      uri: uri || '/graphql',
      fetch: fetch,
      fetchOptions: fetchOptions || {},
      credentials: credentials || 'same-origin',
      headers: headers || {}
    });
    var link = ApolloLink.from([errorLink, requestHandler, stateLink, httpLink].filter(function (x) {
      return !!x;
    }));
    _this = _super.call(this, {
      cache: cache,
      link: link,
      name: name,
      version: version
    }) || this;
    return _this;
  }
  return DefaultClient;
}(ApolloClient);
export default DefaultClient;