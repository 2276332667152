/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';

import Link from '@topginger/ncs-components-link/src';

import * as styles from './footer.module.css';

const Footer: React.FC = ({ children }) => {
  const companyAddress = `10-12 Bourlet Cl, Fitzrovia,
  London, W1W 7BR`;
  const companyEmail = 'info@nicholassolicitors.com';
  const companyFax = '020 7323 4401';
  const companyName = 'Nicholas & Co';
  const companyTelephone = '020 7323 4450';
  const regulationText = 'Authorised and regulated by the Solicitors Regulation Authority, number 626022';

  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.footerUpper}>
          <div>
            {companyName}
            <br />
            {companyAddress}
          </div>
          <div>
            Tel:{' '}
            <Link to={`tel:${companyTelephone}`} external={true} isSecondary={true}>
              {companyTelephone}
            </Link>
            <br />
            Fax: {companyFax}
            <br />
            Email:{' '}
            <Link to={`mailto:${companyEmail}`} external={true} isSecondary={true}>
              {companyEmail}
            </Link>
            <br />
          </div>
          <div>{regulationText}</div>
        </div>
        {children}
      </div>
    </footer>
  );
};

export default Footer;
