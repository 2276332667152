import CSSTransition from 'react-transition-group/CSSTransition';
import * as React from 'react';
import cx from 'classnames';

import Text, { TextStyles } from '@topginger/ncs-components-text/src';
import SVG from '@topginger/ncs-components-svg/src';
import Portal from '@topginger/ncs-components-portal/src';
import { Close } from '@topginger/ncs-components-icons/src';

import * as styles from './edit-tray.module.css';

export interface IEditTrayProps {
  title: string;
  isOpen?: boolean;
  onClose?: () => void;
  side: 'Left' | 'Right';
}

const TIMEOUT = 250;

const EditTray: React.FC<IEditTrayProps> = ({ children, title, isOpen, onClose, side = 'Right' }) => {
  const backdropClassNames = {
    enter: styles.backdropEnter,
    enterActive: styles.backdropEnterActive,
    exit: styles.backdropExit,
    exitActive: styles.backdropExitActive,
  };

  const editTrayClassNames = {
    enter: styles[`editTrayEnter${side}`],
    enterActive: styles.editTrayEnterActive,
    exit: styles.editTrayExit,
    exitActive: styles[`editTrayExit${side}Active`],
  };

  return (
    <Portal>
      <CSSTransition appear={true} classNames={backdropClassNames} unmountOnExit={false} timeout={TIMEOUT} in={isOpen}>
        {(state: string) => (
          <div className={styles.backdrop}>
            <CSSTransition appear={true} classNames={editTrayClassNames} unmountOnExit={true} timeout={TIMEOUT} in={state === 'entered'}>
              <div className={cx(styles.editTray, styles[`editTray${side}`])}>
                <div className={styles.editTrayclose}>
                  <span onClick={onClose} className={styles.closeButton}>
                    <SVG size={24} className={styles.close}>
                      {Close}
                    </SVG>
                  </span>
                </div>
                <div className={styles.editTrayContent}>
                  {title && (
                    <div className={styles.editTrayHeading}>
                      <Text element="h2" type={TextStyles.trayTitle}>
                        {title}
                      </Text>
                    </div>
                  )}
                  {children}
                </div>
              </div>
            </CSSTransition>
          </div>
        )}
      </CSSTransition>
    </Portal>
  );
};

export default EditTray;
