import * as React from 'react';

import ImageCard, { ActionType } from '@topginger/ncs-components-image-card/src';

import * as styles from './home-grid.module.css';

interface IOurServicesGridProps {
  homeLinks: NCS.IHomePageProps[];
  onAction?: any;
}

const HomeGrid: React.FC<IOurServicesGridProps> = ({ homeLinks }) => {
  return (
    <div className={styles.homeGrid}>
      {homeLinks.map(({ linkId, linkTitle, linkImage }) => (
        <ImageCard key={linkId} to={linkId} cardImage={linkImage} cardTitle={linkTitle} actionType={ActionType.link} />
      ))}
    </div>
  );
};

export default HomeGrid;
