import React, { useState } from 'react';

import Layout from '@topginger/ncs-layouts-regular/src';
import Text, { TextStyles } from '@topginger/ncs-components-text/src';
import DropDown from '@topginger/ncs-components-select';
import OurPeopleGrid from '@topginger/ncs-components-our-people-grid/src';

import * as styles from './people.module.css';

interface IOurPeopleProps extends NCS.IPageProps {
  ourPeople: NCS.IPersonProps[];
}

const postionOptions = [
  {
    value: '',
    label: 'All Staff',
  },
  {
    value: 'Partner',
    label: 'Partner',
  },
  {
    value: 'Consultant',
    label: 'Consultant',
  },
  {
    value: 'Senior Associate',
    label: 'Senior Associate',
  },
  {
    value: 'Associate',
    label: 'Associate',
  },
  {
    value: 'Trainee Solicitor',
    label: 'Trainee Solicitor',
  },
];

const PeoplePage: React.FC<IOurPeopleProps> = ({ ourPeople, alert }) => {
  const [position, setPosition] = useState([{ label: '', value: '' }]);

  return (
    <Layout isDark={true} alert={alert}>
      <div className={styles.headerContainer}>
        <Text element="h1" type={TextStyles.pageTitle} isDark>
          Our People
        </Text>
        <DropDown options={postionOptions} onChangeAction={setPosition} />
      </div>
      <OurPeopleGrid ourPeople={ourPeople} filteredPosition={position} />
    </Layout>
  );
};

export default PeoplePage;
