/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';

import Layout from '@topginger/ncs-layouts-regular/src';
import Text, { TextStyles } from '@topginger/ncs-components-text/src';
import StaffCard from '@topginger/ncs-components-staff-card/src';
import List, { ListItem } from '@topginger/ncs-components-list';
import Link from '@topginger/ncs-components-link';
import ContentWrapper from '@topginger/ncs-components-content-wrapper/src';
import BreadCrumb from '@topginger/ncs-components-breadcrumb/src';

import * as styles from './service-details.module.css';

interface IContentDetails extends NCS.IPageProps {
  serviceTitle: string;
  body: any;
  similarServices: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    similar_details_services: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      service_detail_title: string;
      _meta: {
        uid: string;
      };
    };
  }[];
  contactDetails: {
    service_details_contact: {
      name: string;
      position: string;
      _meta: {
        uid: string;
      };

      photo: any;
    };
  }[];
}

const ServiceDetailsPage: React.FC<IContentDetails> = ({ alert, serviceTitle, body, similarServices, contactDetails }) => {
  return (
    <Layout isDark={false} alert={alert}>
      <ContentWrapper>
        <BreadCrumb parentPageUrl="/our-services" parentPageTitle="Our Services" currentPageTitle={serviceTitle} />
        <Text element="h1" type={TextStyles.pageTitle}>
          {serviceTitle}
        </Text>
        {body}
        {contactDetails && contactDetails.length > 0 && (
          <>
            <Text element={'h3'} type={TextStyles.h3}>
              Contact
            </Text>
            <div className={styles.contactGrid}>
              {contactDetails &&
                contactDetails.map(({ service_details_contact }) => {
                  const { name, position, photo, _meta } = service_details_contact || {};

                  return service_details_contact ? (
                    <div key={name}>
                      <StaffCard staffName={name} to={`/our-people/${_meta.uid}`} staffPhoto={(photo && photo[330]) && photo[330].url} staffPosition={position} />
                    </div>
                  ) : null;
                })}
            </div>
          </>
        )}
        {similarServices && similarServices.length > 0 && (
          <>
            <Text element={'h3'} type={TextStyles.h3}>
              Similar services
            </Text>
            <List styled={false}>
              {similarServices &&
                similarServices.map(({ similar_details_services }) => {
                  const { service_detail_title, _meta } = similar_details_services || {};

                  return similar_details_services ? (
                    <ListItem key={_meta.uid}>
                      <Link to={`/our-services/${_meta.uid}`}>{service_detail_title}</Link>
                    </ListItem>
                  ) : null;
                })}
            </List>
          </>
        )}
      </ContentWrapper>
    </Layout>
  );
};

export default ServiceDetailsPage;
