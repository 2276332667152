import * as React from 'react';

import Text from '@topginger/ncs-components-text';
import { Close } from '@topginger/ncs-components-icons';

import * as styles from './alert.module.css';

interface IAlertProps {
  title?: string;
  text?: string;
  alertType?: 'warning' | 'info';
  onClick?: () => void;
}

const Alert: React.FC<IAlertProps> = ({ alertType, title, text, onClick }) => {
  return alertType ? (
    <div className={styles[alertType.toLowerCase()]}>
      <div className={styles.alertWrapper}>
        <Text element="p" type={styles.alertTitle}>
          {title}
          <a role="button" className={styles.closeButton} onClick={onClick}>
            <Close />
          </a>
        </Text>
        <Text element="p" className={styles.alertText}>
          {text}
        </Text>
      </div>
    </div>
  ) : null;
};
export default Alert;
