import * as React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import cx from 'classnames';

import * as styles from './link.module.css';

interface ILinkProps extends GatsbyLinkProps<any> {
  className?: string;
  external?: boolean;
  isDisabled?: boolean;
  isSecondary?: boolean;
}

const Link: React.FC<ILinkProps> = ({ className, to, external, isDisabled, isSecondary, children, ...rest }) => {
  const classNames = cx(styles.link, className, { [styles.secondary]: isSecondary });

  return external ? (
    <a href={to} className={classNames} disabled={isDisabled} {...(rest as any)}>
      {children}
    </a>
  ) : (
    <GatsbyLink to={to} disabled={isDisabled} className={classNames} {...(rest as any)}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
