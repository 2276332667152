
import * as React from 'react';
import { useMediaQuery } from 'react-responsive'

import tokens from '@topginger/ncs-utils-tokens/src';
import Wrapper from '@topginger/ncs-components-wrapper/src';
import Text, { TextStyles } from '@topginger/ncs-components-text/src';

import * as styles from './hero.module.css';

interface IHeroProps {
  heading: string;
  image: any;
}

const { breakpoint } = tokens.customProperties;

const GLOBAL_MEDIA_QUERIES = {
  xxSmall: `(max-width: ${breakpoint.xxs.max}px)`,
  xSmall: `(min-width: ${breakpoint.xs.min}px) and (max-width: ${breakpoint.xs.max}px)`,
  small: `(min-width: ${breakpoint.s.min}px) and (max-width: ${breakpoint.s.max}px)`,
  medium: `(min-width: ${breakpoint.m.min}px) and (max-width: ${breakpoint.m.max}px)`,
  large: `(min-width: ${breakpoint.l.min}px)`,
};

const Hero: React.FC<IHeroProps> = ({ heading, image }) => {
  const isLarge = useMediaQuery({ query: GLOBAL_MEDIA_QUERIES.large })
  const isMedium = useMediaQuery({ query: GLOBAL_MEDIA_QUERIES.medium })
  const isSmall = useMediaQuery({ query: GLOBAL_MEDIA_QUERIES.small })
  const isXSmall = useMediaQuery({ query: GLOBAL_MEDIA_QUERIES.xSmall })
  const isXXSmall = useMediaQuery({ query: GLOBAL_MEDIA_QUERIES.xxSmall })
  const getBackgroundImage = (image) => {
    if (isXXSmall) {
      return image['600'].url;
    } else if (isXSmall) {
      return image['1024'].url;
    }else if (isSmall) {
        return image['1024'].url;
    } else if (isMedium) {
      return image['1440'].url;
    } else if (isLarge) {
      return image['1920'].url;
    } else {
      return image['1920'].url;
    }
  };
  // let backgroundImage;
  // useEffect(() => {
  //   backgroundImage = getBackgroundImage(image)
  // }, [])

  // console.log("backgroundImage", backgroundImage)
  return (
    <div className={styles.hero} style={{ backgroundImage: `url(${getBackgroundImage(image)})` }}>
      <Wrapper>
        <div className={styles.heroHeader}>
          <Text element="h1" type={TextStyles.heroTitle}>
            {heading}
          </Text>
        </div>
      </Wrapper>
    </div>
  );
};

export default Hero;
