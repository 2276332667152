import React from 'react';

import Text, { TextStyles } from '@topginger/ncs-components-text/src';
import List, { ListItem } from '@topginger/ncs-components-list';
import Link from '@topginger/ncs-components-link/src';
import Image from '@topginger/ncs-components-image/src';

import linkResolver from './link-resolver';

export { linkResolver };

// -- Function to add unique key to props
const propsWithUniqueKey = (props, key) => Object.assign(props || {}, { key });

// -- HTML Serializer
const htmlSerializer = (type, element, content, children, key) => {
  let props = {};

  switch (type) {
    case 'heading1': // Heading 1
      return (
        <Text element="h1" type={TextStyles.h1} key={key}>
          {children}
        </Text>
      );

    case 'heading2': // Heading 2
      return (
        <Text element="h2" type={TextStyles.h2} key={key}>
          {children}
        </Text>
      );

    case 'heading3': // Heading 3
      return (
        <Text element="h3" type={TextStyles.h3} key={key}>
          {children}
        </Text>
      );

    case 'heading4': // Heading 4
      return React.createElement('h4', propsWithUniqueKey(props, key), children);

    case 'heading5': // Heading 5
      return React.createElement('h5', propsWithUniqueKey(props, key), children);

    case 'heading6': // Heading 6
      return React.createElement('h6', propsWithUniqueKey(props, key), children);

    case 'paragraph': // Paragraph
      return (
        <Text element="p" type={TextStyles.body} key={key}>
          {children}
        </Text>
      );

    case 'preformatted': // Preformatted
      return React.createElement('pre', propsWithUniqueKey(props, key), children);

    case 'strong': // Strong
      return React.createElement('strong', propsWithUniqueKey(props, key), children);

    case 'em': // Emphasis
      return React.createElement('em', propsWithUniqueKey(props, key), children);

    case 'list-item': // Unordered List Item
      return <ListItem key={key}>{children}</ListItem>;

    case 'olist-item': // Ordered List Item
      return <ListItem key={key}>{children}</ListItem>;

    case 'group-list-item': // Unordered List
      return (
        <List key={key} styled={true}>
          {children}
        </List>
      );

    case 'ogroup-list-item': // Ordered List
      return (
        <List key={key} listType="ol" styled={true}>
          {children}
        </List>
      );

    case 'image': // Image
      const linkUrl = element.linkTo ? element.linkTo.url || linkResolver(element.linkTo) : null;
      const linkTarget = element.linkTo && element.linkTo.target ? { target: element.linkTo.target } : {};
      const linkRel = linkTarget.target ? { rel: 'noopener noreferrer' } : {};
      const img = <Image alt={element.alt || ''} src={element.url} isLazy={true} />;

      return React.createElement(
        'p',
        propsWithUniqueKey({ className: [element.label || '', 'block-img'].join(' ') }, key),
        linkUrl ? React.createElement('a', { href: linkUrl, ...linkTarget, ...linkRel }, img) : img,
      );

    case 'embed': // Embed
      props = Object.assign(
        {
          'data-oembed': element.oembed.embed_url,
          'data-oembed-type': element.oembed.type,
          'data-oembed-provider': element.oembed.provider_name,
        },
        element.label ? { className: element.label } : {},
      );
      const embedHtml = React.createElement('div', { dangerouslySetInnerHTML: { __html: element.oembed.html } });

      return React.createElement('div', propsWithUniqueKey(props, key), embedHtml);

    case 'hyperlink': // Image
      const isTargetBlank = element.data.target === '_blank';
      const rel = isTargetBlank ? { rel: 'noopener noreferrer' } : {};
      props = {
        rel,
      };

      return (
        <Link
          external={isTargetBlank}
          target={isTargetBlank ? '_blank' : undefined}
          to={element.data.url || linkResolver(element.data)}
          {...props}
        >
          {children}
        </Link>
      );

    case 'label': // Label
      props = element.data ? Object.assign({}, { className: element.data.label }) : {};

      return React.createElement('span', propsWithUniqueKey(props, key), children);

    case 'span': // Span
      if (content) {
        return content.split('\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            const br = React.createElement('br', propsWithUniqueKey({}, brIndex));

            return acc.concat([br, p]);
          }
        }, []);
      } else {
        return null;
      }

    default:
      // Always include a default that returns null
      return null;
  }
};

export default htmlSerializer;
