module.exports = [{
      plugin: require('../../../../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"nicholassolicitors","previews":false,"sharpKeys":[],"pages":[{"type":"People","match":"/our-people/:uid","path":"/","component":"/home/runner/work/nicholas_solicitors/nicholas_solicitors/packages/apps/www/src/templates/people.tsx"},{"type":"Content","match":"/:uid","path":"/","component":"/home/runner/work/nicholas_solicitors/nicholas_solicitors/packages/apps/www/src/templates/content.tsx"}]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nicholas Solicitors & Co","short_name":"Nicholas Solicitors & Co","start_url":"/?utm_source=a2hs","background_color":"#d02121","theme_color":"#d02121","display":"standalone","orientation":"portrait","icon":"src/images/manifest-icon.png","include_favicon":true,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"7c057d028619649c2566bba93a7ac88b"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.nicholassolicitors.com"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-31011106-1","head":true},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":".gatsby","tsNode":{"project":"tsconfig.json"}},
    }]
