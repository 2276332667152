import * as React from 'react';

import * as styles from './list.module.css';

interface IListProps {
  listType?: 'ul' | 'ol';
  styled?: boolean;
}

const List: React.FC<IListProps> = ({ children, listType = 'ul', styled = true, ...props }) => (
  <div role="list" className={styles.list}>
    {React.createElement(listType, { className: styled ? styles.styled : null, ...props }, children)}
  </div>
);

export default List;
