import { Organization, WithContext } from 'schema-dts';
import React, { useEffect } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import HomePage from '@topginger/ncs-pages-home/src';

import { siteGlobalSettings } from '../hooks/global-settings';

import SEO from '../components/seo.component';

const People: React.FC = () => {
  const data: any = useStaticQuery(graphql`
    query Home {
      prismic {
        allHomepages {
          edges {
            node {
              areas_of_business {
                link {
                  ... on PRISMIC_Our_services {
                    _meta {
                      uid
                    }
                    page_title
                  }
                  ... on PRISMIC_Our_people {
                    _meta {
                      uid
                    }
                    page_title
                  }
                  ... on PRISMIC_Content {
                    page_title
                    _meta {
                      uid
                    }
                  }
                }
                link_image
              }
              hero_title
              hero_image
              page_description
              page_title
            }
          }
        }
      }
    }
  `);

  const {
    areas_of_business: areasBusiness,
    page_title: pageTitle,
    page_description: pageDescription,
    hero_title: heroTitle,
    hero_image: heroImage,
  } = data.prismic.allHomepages.edges[0].node;

  const homeLinks = areasBusiness.map(
    ({
      link: {
        page_title: linkTitle,
        _meta: { uid: linkId },
      },
      link_image: {
        '620': { url: linkImage },
      },
    }) => ({
      linkId,
      linkTitle,
      linkImage,
    }),
  );

  const ORGANISATION_SCHEMA: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': `https://www.nicholassolicitors.com/#organization`,
    name: 'Nicholas & Co Solicitors',
    legalName: 'Nicholas & Co Solicitors',
    url: 'https://www.nicholassolicitors.com',
    logo: '',
    foundingDate: '',
    address: 'Nicholas & Co 18-22 Wigmore Street London, W1U 2RG',
    contactPoints: [],
    sameAs: [],
  };

  useEffect(() => {
    const props = {
      width: 400,
      r: 5,
      mr: 4,
      defaultposition: true,
    };
    if (typeof global.rs !== 'undefined') {
      global.rs.loadWidget('rswidget', 'side', 9883, props);
    }
  }, []);

  return (
    <>
      <SEO title={pageTitle} desc={pageDescription} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(ORGANISATION_SCHEMA) }} />
      <HomePage alert={siteGlobalSettings()} homeLinks={homeLinks} heroTitle={heroTitle} heroImage={heroImage} />
      {/* <div id="rswidget" style={{ position: 'fixed', right: '0px !important', bottom: '209px !important' }} /> */}
    </>
  );
};

export default People;
