import * as React from 'react';

import Link from '@topginger/ncs-components-link/src';

import * as styles from './breadcrumb.module.css';

interface IBreadCrumbProps {
  parentPageUrl: string;
  parentPageTitle: string;
  currentPageTitle: string;
}

const BreadCrumb: React.FC<IBreadCrumbProps> = ({ parentPageUrl, parentPageTitle, currentPageTitle }) => (
  <ul className={styles.breadcrumb}>
    <li>
      <Link to={parentPageUrl} isSecondary={true}>
        {parentPageTitle}
      </Link>
    </li>
    <li className={styles.seperator}>\</li>
    <li className={styles.currentPage}>{currentPageTitle}</li>
  </ul>
);

export default BreadCrumb;
