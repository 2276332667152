import * as React from 'react';

import Layout from '@topginger/ncs-layouts-regular/src';
import Text, { TextStyles } from '@topginger/ncs-components-text/src';
import PersonContactDetails from '@topginger/ncs-components-person-contact-details/src';
import Image from '@topginger/ncs-components-image';
import ContentWrapper from '@topginger/ncs-components-content-wrapper/src';
import BreadCrumb from '@topginger/ncs-components-breadcrumb/src';

import * as styles from './person.module.css';

interface IPeopleDetails extends NCS.IPageProps {
  name: string;
  position: string;
  photo?: any;
  details: string;
  contactnumber: string;
  contactemail: string;
}

const PersonPage: React.FC<IPeopleDetails> = ({ name, position, photo, details, contactemail, contactnumber, alert }) => {
  return (
    <Layout isDark={false} alert={alert}>
      <ContentWrapper>
        <BreadCrumb parentPageUrl="/our-people" parentPageTitle="Our People" currentPageTitle={name} />
        <Text element="h1" type={TextStyles.pageTitle} className={styles.personName}>
          {name}
        </Text>
        {position && (
          <Text element="p" className={styles.personPosition}>
            {position}
          </Text>
        )}
        {photo && (
          <div className={styles.photoWrapper}>
            {' '}
            <Image src={photo['600'].url} isLazy={true} />
          </div>
        )}
        {details}
        <PersonContactDetails phoneNumber={contactnumber} email={contactemail} />
      </ContentWrapper>
    </Layout>
  );
};

export default PersonPage;
