import * as React from 'react';
import cx from 'classnames';

import Text from '@topginger/ncs-components-text/src';
import Link from '@topginger/ncs-components-link/src';

import * as styles from './image-card.module.css';

export enum ActionType {
  onClick = 'onClick',
  link = 'link',
}

interface IImageCardProps {
  cardTitle: string;
  cardSubTitle?: string;
  cardImage: string;
  to: string;
  actionType?: ActionType;
  onClickAction?: any;
  textPosition?: 'top' | 'bottom';
}

const ImageCard: React.FC<IImageCardProps> = ({
  cardImage,
  cardTitle,
  cardSubTitle,
  to,
  actionType = ActionType.link,
  onClickAction,
  textPosition = 'top',
}) => {
  const cardContent = (
    <>
      <div
        className={cx(styles.imageContainer, {
          [styles.imageTextTop]: textPosition === 'top',
          [styles.imageTextBottom]: textPosition === 'bottom',
        })}
        style={{ backgroundImage: `url(${cardImage})` }}
      >
        <div className={styles.imageText}>
          <Text element={'h3'} className={cx(styles.imageCardTitle)}>
            {cardTitle}
          </Text>
          {cardSubTitle && (
            <Text element={'span'} className={cx(styles.imageCardSubTitle)}>
              {cardSubTitle}
            </Text>
          )}
        </div>
      </div>
    </>
  );

  if (actionType === ActionType.link) {
    return (
      <Link to={to} className={styles.imageCard}>
        {cardContent}
      </Link>
    );
  }

  if (actionType === ActionType.onClick) {
    return (
      <div className={styles.imageCard} onClick={onClickAction}>
        {cardContent}
      </div>
    );
  }

  return null;
};

export default ImageCard;
