const colors = {
  basic: {
    white: '#ffffff',
    black: '#000000',
  },
  link: {
    default: '#ed344e',
  },
  brand: {
    primary: '#d02121',
    secondary: '#262020',
  },
  text: {
    backgroundLight: {
      primary: '#373737',
      secondary: '#808080',
    },
    backgroundDark: {
      primary: '#ffffff',
      secondary: '#bebcbc',
    },
  },
  shade: {
    lightest: '#e8e8e8',
    light: '#979797',
    medium: '#262020',
    dark: '#121111',
  },
};

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const fontSizes = {
  '5xl': '52px',
  '4xl': '42px',
  '3xl': '32px',
  xxl: '28px',
  xl: '24px',
  l: '22px',
  m: '18px',
  s: '16px',
  xs: '14px',
  '3xs': '12px',
  '4xs': '10px',
};

const spacing = {
  none: '0',
  xxs: '8px',
  xs: '16px',
  s: '24px',
  m: '32px',
  l: '40px',
  xl: '48px',
  xxl: '56px',
};

const breakpoints = {
  xxs: { min: 0, max: 599 },
  xs: { min: 600, max: 767 },
  s: { min: 768, max: 1023 },
  m: { min: 1024, max: 1439 },
  l: { min: 1440, max: 1919 },
  xl: { min: 1920, max: Infinity },
};

const mediaQueries = {
  xxs: `(max-width: ${breakpoints.xxs.max}px)`,
  xs: `(min-width: ${breakpoints.xs.min}px)`,
  s: `(min-width: ${breakpoints.s.min}px)`,
  m: `(min-width: ${breakpoints.m.min}px)`,
  l: `(min-width: ${breakpoints.l.min}px)`,
  xl: `(min-width: ${breakpoints.xl.min}px)`,
};

const misc = {
  page: {
    maxWidth: '1240px',
  },
};

export default {
  customProperties: {
    breakpoint: breakpoints,
    color: colors,
    spacing,
    font: {
      size: fontSizes,
      weight: fontWeights,
    },
    misc,
  },
  customMedia: mediaQueries,
  environmentVariables: {},
  customSelectors: {},
};
