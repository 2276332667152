// import cx from 'classnames';
import * as React from 'react';

import StaffCard from '@topginger/ncs-components-staff-card/src';

import * as styles from './our-people-grid.module.css';

interface IOurPeopleGridProps {
  ourPeople: NCS.IPersonProps[];
  filteredPosition: {
    label: string;
    value: string;
  }[];
}

const OurPeopleGrid: React.FC<IOurPeopleGridProps> = ({ ourPeople, filteredPosition = [{ label: '', value: '' }] }) => {
  const [{ value }] = filteredPosition;

  return (
    <div className={styles.peopleGrid}>
      {ourPeople
        .filter(({ position }) => (value !== '' ? position === value : position))
        .map(({ name, position, photo, id }) => {
          return (
            <StaffCard
              key={name}
              to={`/our-people/${id}`}
              staffName={name}
              staffPhoto={photo && photo['600'].url}
              staffPosition={position}
              theme="dark"
            />
          );
        })}
    </div>
  );
};

export default OurPeopleGrid;
