import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ServicesPage from '@topginger/ncs-pages-service/src';

import { siteGlobalSettings } from '../hooks/global-settings';

import SEO from '../components/seo.component';

const Services: React.FC = () => {
  const data: any = useStaticQuery(graphql`
    query OurServices {
      prismic {
        allOur_servicess {
          edges {
            node {
              services {
                list_of_services {
                  _linkType
                  ... on PRISMIC_Services {
                    servicestitle
                    servicessubtitle
                    _meta {
                      uid
                    }
                    servicesimage
                    list_of_services {
                      service_details {
                        ... on PRISMIC_Service_details {
                          service_detail_title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                  }
                }
              }
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  `);

  const [ourServices] = data.prismic.allOur_servicess.edges.map(({ node: { services: services } }) =>
    services.map(
      ({
        list_of_services: {
          servicestitle: servicesTitle,
          servicessubtitle: servicesSubtitle,
          servicesimage: {
            '448x252': { url: servicesImage },
          },
          list_of_services: childServices,
          _meta: { uid: servicesId },
        },
      }) => {
        const childPages =
          childServices &&
          childServices.map(({ service_details: { service_detail_title: childServiceTitle, _meta: { uid: childServiceURL } } }) => ({
            childServiceTitle,
            childServiceURL,
          }));

        return {
          servicesTitle,
          servicesSubtitle,
          servicesImage,
          servicesId,
          childPages,
        };
      },
    ),
  );

  return (
    <>
      <SEO />
      <ServicesPage alert={siteGlobalSettings()} ourServices={ourServices} />
    </>
  );
};

export default Services;
