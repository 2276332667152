import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';

import ContentPage from '@topginger/ncs-pages-content/src';
import htmlSerializer, { linkResolver } from '@topginger/ncs-apps-www/src/utils/html-serializer';

import { siteGlobalSettings } from '../hooks/global-settings';
import SEO from '../components/seo.component';

const Page = ({ data }) => {
  let seoPageTitle;
  let seoPageDescription;
  const contentData = data.prismic.allContents.edges.slice(0, 1).pop();
  if (!contentData) return null;
  const { page_title: pageTitle, content_page_body: pageBody, body } = contentData.node;

  if (body) {
    const { primary } = body[0];
    seoPageTitle = primary.page_title;
    seoPageDescription = primary.page_description;
  }

  return (
    <>
      <SEO title={seoPageTitle} desc={seoPageDescription} />
      <ContentPage
        pageTitle={pageTitle}
        pageBody={RichText.render(pageBody || [], linkResolver, htmlSerializer)}
        alert={siteGlobalSettings()}
      />
    </>
  );
};

export const data = graphql`
  query AllContentQuery($uid: String) {
    prismic {
      allContents(uid: $uid) {
        edges {
          node {
            content_page_body
            body {
              ... on PRISMIC_ContentBodyMeta_data {
                primary {
                  page_title
                  page_description
                }
              }
            }
            page_title
          }
        }
      }
    }
  }
`;

export default Page;
