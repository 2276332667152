import * as React from 'react';

import Link from '@topginger/ncs-components-link/src';

import * as styles from './navigation.module.css';

const Navigation: React.FC = () => {
  return (
    <nav>
      <ul className={styles.primaryNavigation}>
        <li>
          <Link to="/" className={styles.primaryLink}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about-us" className={styles.primaryLink}>
            About
          </Link>
        </li>
        <li>
          <Link to="/our-people" className={styles.primaryLink}>
            People
          </Link>
        </li>
        <li>
          <Link to="/our-services" className={styles.primaryLink}>
            Services
          </Link>
        </li>
        {/* <li>
          <Link to="/pricing" className={styles.primaryLink}>
            Pricing
          </Link>
        </li> */}
        <li>
          <Link to="/contact" className={styles.primaryLink}>
            Contact
          </Link>
        </li>
      </ul>
      <ul className={styles.secondaryNavigation}>
        <li>
          <Link to="/our-fees" className={styles.secondaryLink}>
            Our fees
          </Link>
        </li>
        <li>
          <Link to="/interest-rate-policy" className={styles.secondaryLink}>
            Interest Rate policy
          </Link>
        </li>
        <li>
          <Link to="/legal-information" className={styles.secondaryLink}>
            Legal information
          </Link>
        </li>
        <li>
          <Link to="/privacy" className={styles.secondaryLink}>
            Privacy
          </Link>
        </li>
        <li>
          <Link to="/complaints" className={styles.secondaryLink}>
            Complaints
          </Link>
        </li>
        <li>
          <Link to="/cookie-policy" className={styles.secondaryLink}>
            Cookie policy
          </Link>
        </li>
        <li>
          <Link to="/terms-and-conditions" className={styles.secondaryLink}>
            Ts & Cs
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
