import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';

import PersonPage from '@topginger/ncs-pages-person/src';

import htmlSerializer, { linkResolver } from '@topginger/ncs-apps-www/src/utils/html-serializer';

import { siteGlobalSettings } from '../hooks/global-settings';
import SEO from '../components/seo.component';

const Page = ({ data }) => {
  const personData = data.prismic.allPeoples.edges.slice(0, 1).pop();
  if (!personData) return null;
  const { name, position, photo, details, contactnumber, contactemail } = personData.node;

  return (
    <>
      <SEO title={`${name} - ${position}`} />
      <PersonPage
        name={name}
        position={position}
        photo={photo}
        details={RichText.render(details, linkResolver, htmlSerializer)}
        contactnumber={contactnumber}
        contactemail={contactemail}
        alert={siteGlobalSettings()}
      />
    </>
  );
};

export const data = graphql`
  query AllPeoplesQuery($uid: String) {
    prismic {
      allPeoples(uid: $uid) {
        edges {
          node {
            name
            position
            photo
            details
            contactnumber
            contactemail
          }
        }
      }
    }
  }
`;

export default Page;
