import * as React from 'react';

import * as styles from './list-item.module.css';

const ListItem: React.FC = ({ children }) => (
  <li role="listitem" className={styles.listItem}>
    {children}
  </li>
);

export default ListItem;
