import * as React from 'react';
import cx from 'classnames';

import * as baseStyles from './text.module.css';

export enum TextStyles {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  pageTitle = 'pageTitle',
  body = 'body',
  holdingImageTitle = 'holdingImageTitle',
  heroTitle = 'heroTitle',
  alertTitle = 'alertTitle',
  trayTitle = 'trayTitle',
}

interface ITextProps {
  isDark?: boolean;
  children?: React.ReactNode;
  className?: string;
  element?: any;
  type?: any;
  [prop: string]: any;
}

const Text: React.FC<ITextProps> = ({ isDark, className, children, element, type, color, ...props }) => {
  const finalClassName = cx(className, baseStyles.base, baseStyles[color], (type && baseStyles[type]) || type, {
    [baseStyles.isDark]: isDark,
  });

  return React.createElement(element, { className: finalClassName, ...props }, children);
};

Text.defaultProps = {
  element: 'span',
  type: TextStyles.body,
};

export default Text;
