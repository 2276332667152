import Media from 'react-media';
import React, { useState } from 'react';
import cx from 'classnames';

import SVG from '@topginger/ncs-components-svg/src';
import Navigation from '@topginger/ncs-components-navigation';
import Link from '@topginger/ncs-components-link/src';
import { LogoWhite, LogoBlack, BurgerMenu } from '@topginger/ncs-components-icons/src';
import EditTray from '@topginger/ncs-components-edit-tray';

import * as styles from './header.module.css';

interface IHeaderProps {
  isDark?: boolean;
  className?: string;
  isHomepage?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ isDark, className, isHomepage = false }) => {
  const [isOpen, toggleMenu] = useState<boolean>(false);

  return (
    <header className={cx(styles.header, { [styles.homepageHeader]: isHomepage })}>
      {isOpen && (
        <EditTray title="" isOpen={isOpen} side="Left" onClose={() => toggleMenu(!isOpen)}>
          <Navigation />
        </EditTray>
      )}

      <div className={cx(styles.headerWrapper, className)}>
        <button className={styles.burgerButton} aria-label="show navigation" onClick={() => toggleMenu(!isOpen)}>
          <Media query={{ minWidth: 600 }}>
            {(matches) => (
              <SVG size={matches ? 24 : 14} className={isDark ? styles.whiteBurger : ''}>
                {BurgerMenu}
              </SVG>
            )}
          </Media>
        </button>
        <Link to="/" title="Nicholas & Co Solicitors">
          <Media query={{ minWidth: 600 }}>
            {(matches) => (
              <SVG width={matches ? 204 : 120} height={matches ? 26 : 16}>
                {isDark ? LogoWhite : LogoBlack}
              </SVG>
            )}
          </Media>
        </Link>
      </div>
    </header>
  );
};

export default Header;
