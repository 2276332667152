import * as React from 'react';
import cx from 'classnames';

import Text from '@topginger/ncs-components-text/src';
import Link from '@topginger/ncs-components-link';
import Image from '@topginger/ncs-components-image/src';

import * as styles from './staff-card.module.css';

export enum Theme {
  light = 'light',
  dark = 'dark',
}

interface IStaffCardProps {
  theme?: string;
  staffName: string;
  staffPhoto?: string;
  staffPosition?: string;
  to: string;
}

const StaffCard: React.FC<IStaffCardProps> = ({ theme = 'light', staffName, staffPhoto, staffPosition, to }) => (
  <Link to={to} className={styles.staffCard}>
    <Image className={styles.staffPhoto} src={!staffPhoto ? '/default-user.jpg' : staffPhoto} isLazy={true} />
    <div className={cx(styles.staffDetails, styles[theme])}>
      <Text element={'h3'} className={cx(styles.staffName)}>
        {staffName}
      </Text>
      <Text element={'span'} className={cx(styles.staffPosition)}>
        {staffPosition}
      </Text>
    </div>
  </Link>
);

export default StaffCard;
