import * as React from 'react';

import Layout from '@topginger/ncs-layouts-regular/src';
import Text, { TextStyles } from '@topginger/ncs-components-text/src';
import ContentWrapper from '@topginger/ncs-components-content-wrapper/src';

interface IPeopleDetails extends NCS.IPageProps {
  pageTitle: string;
  pageBody: string;
}

const ContentPage: React.FC<IPeopleDetails> = ({ pageTitle, pageBody, alert }) => {
  const handleCookie = () => {
    console.log('cookies');
    // @ts-ignore
    window.displayPreferenceModal();
  };

  return (
    <Layout isDark={false} alert={alert}>
      <ContentWrapper>
        <Text element="h1" type={TextStyles.pageTitle}>
          {pageTitle}
        </Text>
        {pageBody}
        {pageTitle === 'Cookie Policy' && (
          <button
            className="termly-cookie-preference-button"
            type="button"
            style={{
              background: 'white',
              borderRadius: '3px',
              border: '1px solid #5F7D9C',
              fontFamily: 'Roboto, Arial',
              fontSize: '14px',
              color: '#5F7D9C',
              boxSizing: 'border-box',
              cursor: 'pointer',
              padding: '6px 10px',
              outline: 'none',
            }}
            onClick={() => handleCookie()}
          >
            Manage Cookie Preferences
          </button>
        )}
      </ContentWrapper>
    </Layout>
  );
};

export default ContentPage;
