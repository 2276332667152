import * as React from 'react';
import cx from 'classnames';

import Text, { TextStyles } from '@topginger/ncs-components-text/src';

import * as styles from './holding-image.module.css';

const HoldingImage: React.FC<{ className?: string; text?: string }> = ({ className = '', text = '' }) => {
  return (
    <div className={cx(styles.holdingImage, className)}>
      <Text type={TextStyles.holdingImageTitle} element="h3">
        {text}
      </Text>
    </div>
  );
};

export default HoldingImage;
