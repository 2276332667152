import * as React from 'react';

import cx from 'classnames';
import { isBrowser } from '@topginger/ncs-utils-browser-support/src';
import Wrapper from '@topginger/ncs-components-wrapper/src';
import Hero from '@topginger/ncs-components-hero';
import Header from '@topginger/ncs-components-header';
import Footer from '@topginger/ncs-components-footer';
import AppTemplate from '@topginger/ncs-components-app-template/src';
import Alert from '@topginger/ncs-components-alert';

import * as styles from './home.module.css';

interface ILayoutProps {
  alert: NCS.IAlertProps;
  isDark?: boolean;
  heroTitle: string;
  heroImage: string;
}

const HomeLayoutComponent: React.FC<ILayoutProps> = ({ alert, isDark = false, heroTitle, heroImage, children }) => {
  let isBannerOpen;
  if (isBrowser) {
    isBannerOpen = !sessionStorage.getItem('isBannerOpen');
  }

  const [isOpen, setOpenStatus] = React.useState<boolean>(true);
  const { alertmessagetitle, alertmessagetext, alertmessageactive, alertmessagetype } = alert;
  const handleClose = () => {
    setOpenStatus(false);
    sessionStorage.setItem('isBannerOpen', 'false');
  };

  React.useEffect(() => {
    setOpenStatus(isBannerOpen);
  }, [isBannerOpen]);

  return (
    <AppTemplate isDark={isDark}>
      {alert && alertmessageactive && isOpen && (
        <Alert title={alertmessagetitle} text={alertmessagetext} alertType={alertmessagetype} onClick={handleClose} />
      )}

      <Header isDark={true} isHomepage={true} className={cx({ [styles.isAlertShown]: alertmessageactive && isOpen })} />
      <Hero heading={heroTitle} image={heroImage} />
      <main className={styles.layout}>
        <Wrapper>{children}</Wrapper>
      </main>
      <Footer>
        <div className={styles.footerLower}>
          <iframe
            frameBorder="0"
            scrolling="no"
            allowTransparency={true}
            src="https://cdn.yoshki.com/iframe/55845r.html"
            className={styles.footerImage}
          />
        </div>
      </Footer>
    </AppTemplate>
  );
};
export default HomeLayoutComponent;
