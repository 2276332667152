import * as React from 'react';

import Layout from '@topginger/ncs-layouts-regular/src';
import { useSideTray } from '@topginger/ncs-hooks-side-tray';
import Text, { TextStyles } from '@topginger/ncs-components-text/src';
import OurServicesGrid from '@topginger/ncs-components-our-services-grid';
import EditTray from '@topginger/ncs-components-edit-tray';

// import * as styles from './people.module.css';

interface IOurServicesProps extends NCS.IPageProps {
  ourServices: NCS.IServicesProps[];
}

const ServicePage: React.FC<IOurServicesProps> = ({ alert, ourServices }) => {
  const { handleSideTray, sideTrayContent, clearSideTrayContent } = useSideTray();
  const { title, content } = sideTrayContent;

  return (
    <Layout isDark={true} alert={alert}>
      {!!title && (
        <EditTray title={title} isOpen={!!title} side="Right" onClose={clearSideTrayContent}>
          {content}
        </EditTray>
      )}
      <Text element="h1" type={TextStyles.pageTitle} isDark>
        Our Services
      </Text>
      <OurServicesGrid ourServices={ourServices} onAction={handleSideTray} />
    </Layout>
  );
};

export default ServicePage;
