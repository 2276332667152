/* eslint-disable @typescript-eslint/camelcase */
import Select from 'react-dropdown-select';
import * as React from 'react';

import * as styles from './select.module.css';

interface ISelectOption {
  label: string;
  value: string | number;
}

interface IFieldTextProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options?: ISelectOption[];
  onChangeAction: any;
}

const DropDown: React.FC<IFieldTextProps> = ({ options = [], onChangeAction }) => {
  return (
    <Select
      placeholder="ALL STAFF"
      dropdownPosition="auto"
      className={styles.dropdown}
      options={options}
      separator={false}
      dropdownHandle={true}
      dropdownGap={0}
      onChange={(value) => onChangeAction(value)}
      values={[]}
    />
  );
};

export default DropDown;
