import * as React from 'react';
import { RichText } from 'prismic-reactjs';

import ServiceDetailsPage from '@topginger/ncs-pages-service-details';
import htmlSerializer, { linkResolver } from '@topginger/ncs-apps-www/src/utils/html-serializer';

import { siteGlobalSettings } from '@topginger/ncs-apps-www/src/hooks/global-settings';

import SEO from '../components/seo.component';

const Page = ({ pageContext }) => {
  const { serviceTitle, serviceContent, pageTitle, pageDescription, contactDetails, similarServices } = pageContext;

  return (
    <>
      <SEO title={pageTitle} desc={pageDescription} />
      <ServiceDetailsPage
        alert={siteGlobalSettings()}
        serviceTitle={serviceTitle}
        body={RichText.render(serviceContent || [], linkResolver, htmlSerializer)}
        contactDetails={contactDetails}
        similarServices={similarServices}
      />
    </>
  );
};

export default Page;
