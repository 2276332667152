// import cx from 'classnames';
import * as React from 'react';

import ServicesNavigation from '@topginger/ncs-components-services-navigation/src';
import ImageCard, { ActionType } from '@topginger/ncs-components-image-card/src';

import * as styles from './our-services-grid.module.css';

interface IOurServicesGridProps {
  ourServices: NCS.IServicesProps[];
  onAction?: any;
}

const OurServicesGrid: React.FC<IOurServicesGridProps> = ({ ourServices, onAction }) => {
  return (
    <div className={styles.servicesGrid}>
      {ourServices.map(({ servicesTitle, servicesSubtitle, servicesImage, servicesId, childPages }) => (
        <ImageCard
          key={servicesId}
          to={`/our-services/${servicesId}`}
          cardImage={servicesImage}
          cardTitle={servicesTitle}
          cardSubTitle={servicesSubtitle}
          actionType={ActionType.onClick}
          onClickAction={() => {
            if (childPages) {
              onAction(servicesTitle, <ServicesNavigation parentUrl={servicesId} childPages={childPages} />);
            }
          }}
          textPosition="bottom"
        />
      ))}
    </div>
  );
};

export default OurServicesGrid;
