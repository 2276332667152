export default (doc) => {
  // Pretty URLs for known types
  if (doc.type === 'people') {
    return `/our-people/${doc.uid}`;
  }

  if (doc.type === 'services') {
    return `/our-services/${doc.uid}`;
  }

  // Fallback for other types, in case new custom types get created
  return `/doc/${doc.id}`;
};
