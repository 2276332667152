import * as React from 'react';

import List, { ListItem } from '@topginger/ncs-components-list';
import Link from '@topginger/ncs-components-link/src';

import * as styles from './services-navigation.module.css';

interface IServicesNavigationProps {
  parentUrl: string;
  childPages?: NCS.IChildServicePages[];
}

const ServicesNavigation: React.FC<IServicesNavigationProps> = ({ parentUrl, childPages }) => {
  return (
    <nav>
      {childPages && (
        <List styled={false}>
          {childPages.map(({ childServiceTitle, childServiceURL }, index) => (
            <ListItem key={index}>
              <Link to={`${parentUrl}/${childServiceURL}`} className={styles.serviceNavigationLink}>
                {childServiceTitle}
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </nav>
  );
};

export default ServicesNavigation;
